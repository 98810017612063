function loadScript() {
	const swiperProducts = new Swiper(".js-horScroll-reviews", {
		slidesPerView: "auto",
		spaceBetween: 16,
		navigation: {
			nextEl: ".reviews__btn.swiper-button-next",
			prevEl: ".reviews__btn.swiper-button-prev",
		},
	});
}
setTimeout(loadScript, 4000)