function loadScript() {
	const swiperProducts = new Swiper(".js-horScroll-strategy", {
		slidesPerView: "auto",
		spaceBetween: 16,
		navigation: {
			nextEl: ".strategy__btn.swiper-button-next",
			prevEl: ".strategy__btn.swiper-button-prev",
		},
	});
}
setTimeout(loadScript, 4000)